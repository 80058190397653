import Tooltip from '@mui/material/Tooltip'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { PremiumSearchBoxTabs } from 'src/components/Tabs/PremiumSearchBoxTabs'
import * as enVariables from 'src/config/config'
import { useAutosuggestLazyQuery } from 'src/graphql/generated/hooks'

import { CompanyAutosuggestItem } from './Dropdown/CompanyAutosuggestItem'
import { PremiumAutosuggestItem } from './Dropdown/PremiumAutosuggestItem'

export interface PremiumSearchBoxProps {
  className?: string
}

export const PremiumSearchBox: React.FC<PremiumSearchBoxProps> = ({ className = '', ...props }) => {
  const [searchText, setSearchText] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [searchFocused, setSearchFocused] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const router = useRouter()

  const [autosuggest, { data }] = useAutosuggestLazyQuery()

  const onFocus = () => {
    setSearchFocused(true)
  }
  const onLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => {
        setSearchFocused(false)
      }, 500)
    }
  }
  const onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value ? e.target.value : ''
    setSearchText(text)
    autosuggest({ variables: { keyword: text } })
    setShowPopup(text.length > 0)

    if (text.length > 0) {
      if (enVariables.ENVIRONMENT === 'production') {
        mixpanel.track('search_content', {
          keyword: text,
        })
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      router.push({ pathname: '/search', query: { keyword: searchText } })
    }
  }

  const { categories, solutions, events, contents, companies } = data?.autosuggest || {
    solutions: [],
    categories: [],
    events: [],
    contents: [],
    companies: [],
  }
  const onTabSelect = (idx: number) => {
    setTabIndex(idx)
  }
  const hasAutosuggest = events.length > 0 || categories.length > 0 || solutions.length > 0 || contents.length > 0

  return (
    <div className={cn('relative z-9', className)} onFocus={onFocus} onBlur={onLostFocus}>
      <div
        className={cn(
          'z-20 relative rounded-[1.875rem] border-2 bg-white border-solid flex flex-row items-center pl-[1.3125rem] pr-[0.75rem] h-[3.875rem]',
          searchFocused || showPopup ? 'border-turquoise shadow-md' : 'border-white',
        )}
        {...props}
      >
        <IonIcon name="search" className="mr-2 w-[0.9225rem] h-[0.9225rem]" />
        <input
          autoFocus
          type="text"
          className="focus:outline-none grow font-karla font-medium text-textdark  text-[14px] mr-[14px] leading-[42px] search-text"
          placeholder="Search by keyword, product name, etc."
          onChange={onTextChanged}
          onKeyDown={handleKeyDown}
        />
        <Link href={{ pathname: '/search', query: { keyword: searchText } }}>
          {searchText === '' ? (
            <Tooltip title="Search all results" placement="right">
              <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
                <IonIcon className="mr-0 !w-[20px] !h-[20px]" name="arrow-forward-sharp" />
              </div>
            </Tooltip>
          ) : (
            <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
              <IonIcon className="mr-0 !w-[20px] !h-[20px]" name="arrow-forward-sharp" />
            </div>
          )}
        </Link>
      </div>
      <div
        className={cn(
          'absolute z-10 top-0 rounded-[1.875rem] bg-white shadow-md w-full pt-[4.2rem] pb-2 overflow-hidden flex flex-col',
          {
            hidden: !showPopup || !hasAutosuggest || !searchFocused,
          },
        )}
        tabIndex={0}
      >
        <div className="w-full flex-1 flex flex-col items-center">
          <div className="w-full">
            <div className="w-full px-5 pt-1">
              <PremiumSearchBoxTabs
                className="w-1/10 mx-auto text-[1rem] text-black"
                tabs={['ALL', 'CONTENT', 'DIRECTORY', 'TOPICS', 'COMPANIES', 'EVENTS']}
                counts={[0, 0, 0, 0, 0]}
                showNumber={false}
                onTabSelect={onTabSelect}
              />
            </div>
            <div className="w-full bg-navy opacity-20 h-[1px]" />
          </div>
          <div className="w-full">
            <div className="overflow-y-auto max-h-[35rem] w-full pt-2">
              {(tabIndex === 0 || tabIndex === 1) && (
                <>
                  {tabIndex === 0 && contents.length > 0 && (
                    <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                      Contents
                    </p>
                  )}
                  <div className="w-full">
                    {contents.map((data, idx) => (
                      <PremiumAutosuggestItem data={data} search={searchText} key={idx} />
                    ))}
                    {contents.length === 0 && tabIndex === 1 && (
                      <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                        No content found matching keyword {searchText}
                      </p>
                    )}
                  </div>
                </>
              )}
              {(tabIndex === 0 || tabIndex === 2) && (
                <>
                  {tabIndex === 0 && solutions.length > 0 && (
                    <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                      Solutions
                    </p>
                  )}
                  <div className="w-full">
                    {solutions.map((data, idx) => (
                      <PremiumAutosuggestItem data={data} search={searchText} key={idx} />
                    ))}
                    {solutions.length === 0 && tabIndex === 2 && (
                      <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                        No solution found matching keyword {searchText}
                      </p>
                    )}
                  </div>
                </>
              )}
              {(tabIndex === 0 || tabIndex === 3) && (
                <>
                  {tabIndex === 0 && categories.length > 0 && (
                    <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                      Categories
                    </p>
                  )}
                  <div className="w-full">
                    {categories.map((data, idx) => (
                      <PremiumAutosuggestItem data={data} search={searchText} key={idx} icon={false} />
                    ))}
                    {categories.length === 0 && tabIndex === 3 && (
                      <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                        No category found matching keyword {searchText}
                      </p>
                    )}
                  </div>
                </>
              )}
              {(tabIndex === 0 || tabIndex === 4) && (
                <>
                  {tabIndex === 0 && companies.length > 0 && (
                    <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">
                      Companies
                    </p>
                  )}
                  <div className="w-full">
                    {companies.map((data, idx) => (
                      <CompanyAutosuggestItem data={data} search={searchText} key={idx} isPremiumSearch />
                    ))}
                    {companies.length === 0 && tabIndex === 4 && (
                      <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                        No companies found matching keyword {searchText}
                      </p>
                    )}
                  </div>
                </>
              )}
              {(tabIndex === 0 || tabIndex === 5) && (
                <>
                  {tabIndex === 0 && events.length > 0 && (
                    <p className="w-full px-8 font-karla font-normal text-darkgray text-[.8125rem] leading-8">Events</p>
                  )}
                  <div className="w-full">
                    {events.map((data, idx) => (
                      <PremiumAutosuggestItem data={data} search={searchText} key={idx} />
                    ))}
                    {events.length === 0 && tabIndex === 5 && (
                      <p className="pl-[50px] pt-[10px] pb-[10px] font-karla font-normal text-darkgray">
                        No event found matching keyword {searchText}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
