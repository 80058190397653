import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
//import { CollectionCard } from 'src/components/CollectionCard/CollectionCard'
import { useGetCollectionsQuery } from 'src/graphql/generated/hooks'

// Lazy load CollectionCard
const CollectionCard = dynamic(
  () => import('src/components/CollectionCard/CollectionCard').then((mod) => mod.CollectionCard),
  {
    suspense: true, // Enable Suspense fallback for smoother loading
    loading: () => <p style={{ backgroundColor: '#F1F3F5' }}></p>,
  },
)

export const Collections: NextPage = () => {
  const { data } = useGetCollectionsQuery()
  const [collectionData, setCollectionData] = useState([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAllfeaturedOnHomepage = (data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.filter((item: any) => item.attributes.featuredOnHomepage && item.attributes.contents.data.length > 0)
  }

  useEffect(() => {
    let dt = []
    if (data && data.collections && data.collections.data && data.collections.data.length > 0) {
      dt = getAllfeaturedOnHomepage(data.collections.data)
      // Create a shallow copy of the collections array and sort by ID in descending order
      dt = dt.slice().sort((a: any, b: any) => b.id - a.id)
      setCollectionData(dt)
    }
  }, [data])

  return (
    <>
      {collectionData && collectionData.length > 0 && (
        <div className="w-full px-3 large-lg:px-0 min-h-[20.75rem] bg-clouds pb-6">
          <div className="!max-w-[1100px] flex flex-col justify-start mx-auto">
            <span className="font-secondary font-bold text-[1.5rem] text-black text-center md:text-left md:mr-10">
              Collections
            </span>
            <div className="grid md:grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mb-5 mt-[1rem]">
              {collectionData.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (collection: any, idx: number) =>
                  idx < 4 &&
                  collection.attributes.featuredOnHomepage && (
                    <CollectionCard
                      key={collection.id} // Add a key prop for each CollectionCard
                      title={collection.attributes.name}
                      url={`/collections/${collection.attributes.slug}?tab=content`}
                      description={collection.attributes.description}
                    />
                  ),
              )}
            </div>

            <Link
              href={`/collections`}
              className="text-center font-secondary font-bold text-[1rem] text-textdark underline cursor-pointer"
            >
              View All
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
