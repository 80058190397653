import { Hidden } from '@mui/material'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { IPopularTopic } from 'src/graphql/generated/hooks'

import { PopularTopicItem } from './PopularTopicItem'
import { PopularTopicListing } from './PopularTopicListing'

export interface PopularTopicsProps {
  className?: string
  topics: IPopularTopic[]
}

export const PopularTopics: React.FC<PopularTopicsProps> = ({ className = '', topics }) => {
  const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)
  const [curIdx, setCurIdx] = useState(0)
  const [started, setStarted] = useState(false)
  const [anim, setAnim] = useState<NodeJS.Timeout>()

  useEffect(() => {
    setPopularSectionAnimation()
  }, [started, curIdx])

  const step = 0.035
  const speed = 10

  function setPopularSectionAnimation() {
    let changed = false
    let sign = 1
    const elem = document.getElementById('popularTopicContent') || null
    if (elem !== null) {
      elem.style.opacity = '1'
    }

    anim && clearInterval(anim)

    const interval = setInterval(function () {
      if (elem !== null && started) {
        const opacity = Number(elem.style.opacity)
        elem.style.opacity = '' + (opacity - sign * step)
        if (opacity <= 0) {
          if (!changed) {
            changed = true
            setTopicCurrentIdx(curIdx)
          }
          sign = -1
        } else if (opacity > 1) {
          elem.style.opacity = '1'
          changed = false
          sign = 1
          setStarted(false)
          clearInterval(interval)
        }
      }
    }, speed)

    setAnim(interval)
  }

  function selectPreviousTopic() {
    const newIdx = curIdx <= 0 ? topics.length - 1 : curIdx - 1
    setCurIdx(newIdx)
    setStarted(true)
  }

  function selectNextTopic() {
    const newIdx = curIdx < topics.length - 1 ? curIdx + 1 : 0
    setCurIdx(newIdx)
    setStarted(true)
  }

  function onTopicItemMouseOver(idx: number) {
    setCurIdx(idx)
    setStarted(true)
  }

  const solutions = topics[topicCurrentIdx]?.solutions || []

  return (
    <div className={cn(className, 'w-full px-3 large-lg:px-0 min-h-[38.375rem] bg-clouds')}>
      {/* Web View */}
      <Hidden mdDown>
        <div className="!max-w-[1100px] flex flex-col items-center mx-auto py-10">
          <p className="text-[#222222] font-secondary font-bold text-[1.5rem] tracking-normal text-center leading-9">
            Most Popular Topics
          </p>
          <div className="w-full pt-8 pb-3 grid sm:grid-cols-2 md:flex md:justify-between md:items-center">
            <ul className="col-span-1 sm:col-span-2 min-w-[17rem]">
              {topics.map((topic, idx) => (
                <PopularTopicItem key={idx} topic={topic} onMouseOver={() => onTopicItemMouseOver(idx)} />
              ))}
            </ul>
            <div
              id="popularTopicContent"
              className="grid grid-cols-2 gap-4 sm:grid-cols-3 sm:col-span-2 md:gap-2 md:grid-cols-3"
            >
              {solutions.map((solution, idx) => (
                <PopularTopicListing key={idx} vendor={solution} />
              ))}
            </div>
          </div>
        </div>
      </Hidden>
      {/* Mobile View */}
      <Hidden mdUp>
        <div className="!max-w-[1100px] flex flex-col items-center mx-auto py-10">
          <p className="text-[#222222] font-secondary font-bold text-[1.5rem] tracking-normal text-center leading-9">
            Most Popular Topics
          </p>
          <div className="w-full pt-5 pb-5 grid sm:grid-cols-2 md:flex md:justify-between md:items-center">
            <div className="flex flex-row pb-5 w-full pl-[1.25rem] pr-[1.25rem] items-center">
              <div className="cursor-pointer w-[2.5rem]" onClick={selectPreviousTopic} key="1">
                <IonIcon name="chevron-back-outline" className="!w-[40px] !h-[40px]" />
              </div>
              <p className="text-center md:text-left text-[#222222] font-secondary font-bold text-[1.0rem] tracking-normal hover:underline hover:font-bold  pb-3 underline underline-offset-[0.15rem] decoration-turquoise decoration-solid decoration-[.3125rem] ml-auto mr-auto">
                {topics && topics.length > 0 && topics[curIdx].name}
              </p>
              <div className="cursor-pointer w-[2.5rem]" onClick={selectNextTopic} key="2">
                <IonIcon name="chevron-forward-outline" className="!w-[40px] !h-[40px]" />
              </div>
            </div>
            <div
              id="popularTopicContent"
              className="grid grid-cols-2 gap-2 sm:grid-cols-3 sm:col-span-2 md:gap-2 md:grid-cols-3 pl-[1.25rem] pr-[1.25rem]"
            >
              {solutions.map((vendor, idx) => (
                <PopularTopicListing key={idx} vendor={vendor} />
              ))}
            </div>
          </div>
        </div>
      </Hidden>
    </div>
  )
}

export default PopularTopics
