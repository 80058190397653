import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import IonIcon from '@reacticons/ionicons'
import React from 'react'
import Modal from 'react-modal'

// import { NewsletterForm } from './NewsletterForm/NewsletterForm'

interface NewsletterModalProps {
  isOpen: boolean
  onClose: () => void
}

const newsletterModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '580px',
    marginRight: '-50%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
    borderWidth: '0px',
    boxShadow: '0 0 2px 2px rgba(180, 183, 197, 0.9)',
    width: '50%',
  },
  overlay: {
    zIndex: 1000,
  },
}

export const NewsletterModal: React.FC<NewsletterModalProps> = ({ isOpen, onClose }) => {
  if (isOpen) {
    useHubspotForm({
      portalId: '21422664',
      formId: 'bf3eb4b1-28be-4673-a308-5b95221f87b4',
      target: '#my-hubspot-form',
    })
  }

  return (
    <Modal
      id="newsletter-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      style={newsletterModalStyles}
      ariaHideApp={false}
    >
      <div
        className="w-full items-center justify-between"
        style={{
          background: '#fff',
          width: '96%',
          height: '320px',
          borderRadius: '15px',
          padding: '20px 0px 20px 20px',
          zIndex: '100',
          marginLeft: '10px',
          marginRight: '10px',
        }}
      >
        <div className="flex flex-row w-full">
          <span className="w-full font-primary font-bold text-textdark opacity-70 text-[18px] text-center">
            Subscribe to Insights
          </span>
          <a className="flex items-center ml-auto" onClick={onClose}>
            <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-navy" />
          </a>
        </div>
        <div className="flex flex-col pl-[1.25rem] pr-[1.25rem] !max-w-[1100px] w-full py-2 m-auto">
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-full ">
              <div id="my-hubspot-form"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <NewsletterForm onClose={onClose} /> */}
    </Modal>
  )
}
