import cn from 'classnames'
import Image from 'next/image'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { LinkWithOrigin } from 'src/components/LinkWithOrigin/LinkWithOrigin'
import { ISuggest } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'

interface CompanyAutosuggestItemProps {
  data: ISuggest
  search: string
  isPremiumSearch?: boolean
}

export const CompanyAutosuggestItem: React.FC<CompanyAutosuggestItemProps> = ({ data, search, isPremiumSearch }) => {
  const logo = useDefaultLogo(data.logo)

  return (
    <LinkWithOrigin toUrl={data.slug} className="flex w-full relative">
      <div
        className={cn(
          'w-full flex bg-white hover:bg-gray-400 cursor-pointer items-center px-8',
          isPremiumSearch ? '!h-[50px] !px-10' : '',
        )}
      >
        <Image
          className="border border-solid border-mist rounded-[.1875rem]"
          src={logo}
          alt="logo"
          width={isPremiumSearch ? 40 : 20}
          height={isPremiumSearch ? 40 : 20}
          priority
        />
        <Highlighter
          className={cn(
            isPremiumSearch
              ? `font-primary font-normal text-[1rem] text-[#162e64] leading-[16px] ${logo && 'ml-6'}`
              : 'text-textdark font-primary font-normal text-[.875rem] leading-7 ml-3',
          )}
          highlightClassName={cn(isPremiumSearch ? 'font-bold bg-white text-[#162e64]' : 'font-bold')}
          searchWords={[search]}
          textToHighlight={data.keyword}
        />
      </div>
    </LinkWithOrigin>
  )
}
