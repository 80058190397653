import cn from 'classnames'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { Collections } from 'src/components/Collections/Collections'
//#import { ExpertsCarousel } from 'src/components/ExpertsCarousel/ExpertsCarousel'
import { Footer } from 'src/components/Footer/Footer'
//#import { PremiumModal } from 'src/components/global/PremiumModal'
import { ThemeOnly } from 'src/components/global/ThemeOnly'
import { Header } from 'src/components/Header/Header'
import { Hero } from 'src/components/Hero/Hero'
import { PopularTopics } from 'src/components/Home/PopularTopics/PopularTopics'
import { HomeTopics } from 'src/components/HomeTopics/HomeTopics'
import { IltaFooter } from 'src/components/IltaFooter/IltaFooter'
import { IltaSponsorshipLevels } from 'src/components/IltaSponsorshipLevels/IltaSponsorshipLevels'
import { LastFromVendorCheatsheet } from 'src/components/LastFromVendorCheatsheet/LastFromVendorCheatsheet'
import { PremiumContentCategories } from 'src/components/PremiumContentCategories/PremiumContentCategories'
import { Testimonials } from 'src/components/Testimonials/Testimonials'
//import { TrendingTopics } from 'src/components/TrendingTopics/TrendingTopics'
import { THEME, Theme } from 'src/config/config'
import {
  IIltaSponsorshipItemFragment,
  IPopularTopic,
  IPremiumAudienceItemFragment,
  ISubTopicItemFragment,
  ITestimonialItemFragment,
  ITopicItemFragment,
} from 'src/graphql/generated/hooks'
//#import { VendorSection } from './VendorSection'
//import { useTrendingContentsByPremiumAudienceQuery } from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { Meta } from 'src/layout/Meta'
import { Main } from 'src/templates/Main'

// import { BuyerSection } from './BuyerSection'
import { HomePremiumSection } from './HomePremiumSection'
import { PremiumCategories } from './PremiumCategories'
//import { VendorSection } from './VendorSection'

interface BasicHomePageProps {
  theme: Theme
}
interface MainHomePageProps extends BasicHomePageProps {
  theme: 'legaltech'
  audiences: IPremiumAudienceItemFragment[]
  premiumCategories: (ITopicItemFragment | ISubTopicItemFragment)[]
  topics: ITopicItemFragment[]
  testimonials: ITestimonialItemFragment[]
  popularTopics: IPopularTopic[]
}

interface IltaHomePageProps extends BasicHomePageProps {
  theme: 'ilta'
  audiences?: IPremiumAudienceItemFragment[]
  topics: ITopicItemFragment[]
  iltaSponsorships: IIltaSponsorshipItemFragment[]
}

export type HomePageProps = MainHomePageProps | IltaHomePageProps

const isMainHomePageProps = (props: HomePageProps): props is MainHomePageProps => props.theme === 'legaltech'
const isIltaHomePageProps = (props: HomePageProps): props is IltaHomePageProps => props.theme === 'ilta'

const Home: NextPage<HomePageProps> = (props) => {
  // dynamic component imports
  const VendorSection = dynamic(() => import('./VendorSection').then((mod) => mod.VendorSection), { ssr: false })
  const ExpertsCarousel = dynamic(
    () => import('src/components/ExpertsCarousel/ExpertsCarousel').then((mod) => mod.ExpertsCarousel),
    {
      loading: () => <p style={{ backgroundColor: '#F1F3F5' }}></p>,
    },
  )
  const PremiumModal = dynamic(() => import('src/components/global/PremiumModal').then((mod) => mod.PremiumModal), {
    loading: () => <p style={{ backgroundColor: '#062150' }}></p>,
  })
  const TrendingTopics = dynamic(
    () => import('src/components/TrendingTopics/TrendingTopics').then((mod) => mod.TrendingTopics),
    {
      loading: () => <p style={{ backgroundColor: '#062150' }}></p>,
    },
  )

  const { topics } = props
  const [isPremiumPreview, setIsPremiumPreview] = useState(false)
  const [isPremiumModalVisible, setIsPremiumModalVisible] = useState(false)
  const { isPremium, isVendor } = useAuthenticated()
  const title = 'Legaltech Hub'
  const description =
    'Legaltech Hub is the global directory of legal technology solutions, tools and services where buyers of legal technology can research the legal tech they need to manage a 21st century law firm or in-house department, vendors can showcase their solutions, and investors can understand the marketplace.'

  const meta = <Meta title={title} description={description} url="/" />
  const isPremiumMode = isPremium || isPremiumPreview

  /* const [trendingContentsByPremiumAudience, setTrendingContentsByPremiumAudience] = useState<any>([])
  const audienceNames: string[] = (props?.audiences ?? []).map((item: any) => item?.attributes?.name)

  const { data, error } = useTrendingContentsByPremiumAudienceQuery({
    fetchPolicy: 'cache-and-network',
    variables: { audience: audienceNames },
  })

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch trending contents:', error)
      setTrendingContentsByPremiumAudience([])
    }
    const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000 // current time + 1 day in milliseconds
    localStorage.setItem('topics', JSON.stringify({ topics: topics, expiry: expirationTime }))

    if (data) {
      setTrendingContentsByPremiumAudience(data.trendingContentsByPremiumAudience)
    }
  }, [data, error]) */
  return (
    <Main meta={meta}>
      <ThemeOnly theme="legaltech">
        <Header key={'Header-1'} />
      </ThemeOnly>
      <PremiumModal
        key={'PM-1'}
        origin="/home/"
        isOpen={isPremiumModalVisible}
        onClose={() => setIsPremiumModalVisible(false)}
      />
      <Hero key={'Hero-1'} isPremiumPreview={isPremiumPreview} onPreviewPremium={() => setIsPremiumPreview(true)} />
      <h1 className="hidden">Legaltech Hub</h1>
      {!isPremiumMode && (
        <div>
          <div className="w-full px-3 large-lg:px-0 min-h-[28.75rem] bg-clouds pb-6">
            <div className="!max-w-[1100px] flex flex-col justify-center m-auto">
              <span
                className={cn('text-center mt-10 mb-6 font-bold text-[1.5rem] text-[#222222] font-secondary', {
                  '!text-[#3D4246] !font-primary': THEME === 'ilta',
                })}
              >
                Browse by Topic
              </span>
              <HomeTopics key={'HomeTopics-1'} className={cn({ '!text-textdark': THEME === 'ilta' })} topics={topics} />
            </div>
          </div>
          {isMainHomePageProps(props) && (
            <ThemeOnly theme="legaltech">
              <HomePremiumSection key={'HomePremiumSection-1'} />

              {/* <BuyerSection /> */}
              <VendorSection key={'VendorSection-1'} />
              <PopularTopics key={'PopularTopics-1'} topics={props.popularTopics} />
              <Testimonials key={'Testimonials-1'} testimonials={props.testimonials} />
            </ThemeOnly>
          )}
        </div>
      )}
      <ThemeOnly theme="legaltech">
        {isPremiumMode && isMainHomePageProps(props) && (
          <div key={'PremiumContent-1'}>
            <PremiumContentCategories key={'PremiumContentCategories-1'} isVendor={isVendor} />
            <PremiumCategories key={'PCategories-1'} premiumCategories={props.premiumCategories} topics={topics} />
          </div>
        )}
        {isVendor && isPremiumMode && <LastFromVendorCheatsheet key={'LastFromVendorCheatsheet-1'} />}
        {isPremiumMode && isMainHomePageProps(props) && (
          <div>
            {/* {SHOW_COLLECTION && <Collections />} */}
            <Collections key={'Collections-1'} />
            {props?.audiences?.map((audience) => (
              <TrendingTopics
                key={audience.id!}
                audience={audience}
                onClickWhenDisabled={() => setIsPremiumModalVisible(true)}
              />
            ))}
            {/* {trendingContentsByPremiumAudience?.map((audience: any) => (
              <TrendingTopics
                key={audience.id!}
                audience={audience}
                onClickWhenDisabled={() => setIsPremiumModalVisible(true)}
              />
            ))} */}
            <ExpertsCarousel key={'ExpertsCarousel-1'} />
          </div>
        )}
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <div key={'Browse-by-sponsor-levels-1'} className="w-full px-3 large-lg:px-0 min-h-[28.75rem] bg-white pb-6">
          <div className="!max-w-[1100px] flex flex-col justify-center m-auto">
            <span className="text-center mt-10 mb-6 font-bold text-[2rem] text-textdark font-primary">
              Browse by sponsor levels
            </span>
            <span className="text-center mb-6 font-secondary text-[16px]">
              Search for ILTA business partners and consultants who offer products and services in the legal community.
            </span>
            <div className="mt-[20px] mb-[88px]">
              {isIltaHomePageProps(props) && <IltaSponsorshipLevels litaSponsorships={props.iltaSponsorships} />}
            </div>
          </div>
        </div>
      </ThemeOnly>

      <ThemeOnly theme="legaltech">
        <Footer key={'Footer-1'} />
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <IltaFooter key={'IltaFooter-1'} />
      </ThemeOnly>
    </Main>
  )
}

export default Home
