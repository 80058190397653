import { Hidden } from '@mui/material'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import premiumtop from 'src/assets/premiumtop.png'
import HomeBuyVendorBadge from 'src/components/global/HomeBuyVendorBadge/HomeBuyVendorBadge'
import { IconButton } from 'src/components/global/IconButton/IconButton'
import { premiumPlanFeatures } from 'src/constants/constants'

import styles from './Home.module.scss'

interface HomePremiumSectionProps {
  className?: string
}

interface PremiumInfo {
  premiumhead: string
  premiumcontent: string
  premiumfirms: string
  premiumdepartments: string
}

const premiumInfo: PremiumInfo = {
  premiumhead: 'LTH Premium for Law Firms and In-House',
  premiumcontent:
    'Get access to high-value expert-driven articles and resources that improve transparency around legaltech procurement, implementation and adoption.',
  premiumfirms: '',
  premiumdepartments: '',
}

/**
 * Primary UI component for user interaction
 */
export const HomePremiumSection: React.FC<HomePremiumSectionProps> = ({ className, ...props }) => {
  const data = premiumInfo
  return (
    <div className={cn(className, 'flex flex-row justify-center')} {...props}>
      <div className="px-[1.25rem] !max-w-[1100px] w-full flex flex-row items-center py-2">
        <Hidden mdDown>
          <div className="grid py-8 gap-[5rem] sm:grid-cols-2">
            <div className="self-center ">
              <div className={cn(styles.homeBuyPremiumBadge)}></div>
              <div className="pt-[0.5rem] pb-[0.625rem] text-[24px] leading-[35px] font-bold font-secondary text-[#222222]">
                {data.premiumhead}
              </div>
              <div className=" text-[18px] font-primary font-regular text-darkgray">{data.premiumcontent}</div>
              <div className="mt-5">
                <ul className="list-inside ...">
                  {premiumPlanFeatures.map((option, index) => (
                    <div key={index} className="flex flex-row items-center">
                      <IonIcon
                        name="checkmark-circle"
                        style={{ color: '#3329EF', marginRight: '8px', height: '16px', width: '16px' }}
                      />
                      <span className="font-primary font-regular text-[18px] leading-[26px] text-darkgray">
                        {option}
                      </span>
                    </div>
                  ))}
                </ul>
              </div>
              {/* <Link target={'_blank'} href={'https://hub.legaltechnologyhub.com/firm-premium-home'}> */}
              <Link href={'/lth-solution/law-firms-in-house/'}>
                <IconButton type="primary" label="More Information" className="w-[9.625rem] h-[3.125rem] pt-5" />
              </Link>
            </div>
            <div className="pt-10">
              <div className="md:float-right">
                <Image className="" src={premiumtop} alt="" />
              </div>
            </div>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className="flex flex-col">
            <div className="self-center py-8">
              <HomeBuyVendorBadge />
              <div className="pt-[0.5rem] pb-[0.625rem] text-[1.5rem] font-bold font-secondary text-[#222222]">
                {data.premiumhead}
              </div>
            </div>
            <div>
              <div className="py-4">
                <Image className="" src={premiumtop} alt="" />
              </div>
            </div>
          </div>
        </Hidden>
      </div>
    </div>
  )
}
