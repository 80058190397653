import cn from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import { TopicsMenu } from 'src/components/Hero/TopicsMenu'
import { useGetTopicsForHeaderQuery } from 'src/graphql/generated/hooks'

interface Props {
  displayTitle?: boolean
  className?: string
  menuClassName?: string
}

export const IltaHeader: React.FC<Props> = ({ displayTitle = true, className, menuClassName }) => {
  const { data } = useGetTopicsForHeaderQuery({ fetchPolicy: 'cache-first' })
  const topicsData = data?.topics?.data || []
  const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)

  return (
    <div className={cn('w-full flex item-end justify-center bg-hero min-h-[100px]', className)}>
      <div className="flex items-end justify-between w-full pb-2 !max-w-[1100px]">
        <div>
          {displayTitle && (
            <Link className="text-[18px] text-white font-primary font-bold" href={'/'}>
              Sponsor Directory Home
            </Link>
          )}
        </div>
        <div className="flex items-center justify-center max-h-full">
          <TopicsMenu
            topicsData={topicsData}
            topicCurrentIdx={topicCurrentIdx}
            handleSetTopicCurrentIdx={setTopicCurrentIdx}
            menuClassName={menuClassName}
          />
        </div>
      </div>
    </div>
  )
}
