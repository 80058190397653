import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import React from 'react'
import { THEME } from 'src/config/config'

import styles from './IconButton.module.css'

interface IconButtonProps {
  type?: 'primary' | 'secondary' | 'highlighted' | 'icontopic'
  label: string
  icon?: string
  disabled?: boolean
  onClick?: () => void
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const IconButton: React.FC<IconButtonProps> = ({
  type = 'primary',
  label,
  icon,
  disabled = false,
  className,
  onClick,
  ...props
}) => {
  return (
    <div className={cn(`${type}`, `${className}`)}>
      <button
        type="button"
        className={cn(styles.iconButton, styles[`button--${type}`], 'w-full h-full')}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {icon && (
          <div className="ml-6 flex items-center">
            <IonIcon
              className="!w-[25px] !h-[25px]"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              name={icon as any}
            />
            <span className={cn('ml-3', { 'font-primary font-semibold text-[14px]': THEME === 'ilta' })}>{label}</span>
          </div>
        )}
        {!icon && label}
      </button>
    </div>
  )
}
