import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Image from 'next/image'
import React from 'react'
import Slider from 'react-perfect-slider'
import { HtmlContentViewer } from 'src/components/global/HtmlContentViewer/HtmlContentViewer'
import { ITestimonialItemFragment } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'
import { removeTags } from 'src/utils/removeTags'

import styles from './Testimonials.module.scss'

export interface TestimonialProps {
  testimonials: ITestimonialItemFragment[]
}

export const Testimonials: React.FC<TestimonialProps> = (props) => {
  const data = props.testimonials
  const defaultLogo = useDefaultLogo()

  return (
    <div className={cn('w-full px-5 sm:px-0 flex flex-row justify-center bg-white')}>
      <div className="max-w-screen-xl mx-32 w-full">
        <Slider
          renderControls={(next, previous) => [
            <div className="w-auto absolute top-1/2 left-0 -translate-y-1/2 cursor-pointer" onClick={previous} key="1">
              <IonIcon name="chevron-back-outline" className="!h-[30px] !w-[30px] text-[black] ml-auto"></IonIcon>
            </div>,
            <div className="w-auto absolute top-1/2 right-0 -translate-y-1/2 cursor-pointer" onClick={next} key="2">
              <IonIcon name="chevron-forward-outline" className="!h-[30px] !w-[30px] text-[black] ml-auto"></IonIcon>
            </div>,
          ]}
        >
          {data.map((info, idx) => {
            const description = `“${removeTags(info.attributes?.description || '')}”` || ''
            const title = [
              info.attributes?.author?.name,
              info.attributes?.author?.role,
              info.attributes?.author?.company,
            ]
              .filter((item) => !!item)
              .join(', ')

            return (
              <div className="flex flex-col items-center my-16 gap-8" key={idx}>
                <div>
                  <HtmlContentViewer
                    className="text-center max-w-screen-md font-secondary font-regular px-10 md:px-0 md:text-xl md:text-2xl text-[#202A35]"
                    html={description}
                  />
                  <div className={styles.avatarTestmonialContainer}>
                    <Image
                      src={info.attributes?.author?.avatar?.data?.attributes?.url || defaultLogo}
                      alt="avatar"
                      className={styles.avatarTestmonial}
                      width={1000}
                      height={1000}
                      priority
                    />
                  </div>
                  {info.attributes?.author && (
                    <p className="font-primary font-regular text-darkgray text-textdark text-center">{title}</p>
                  )}
                </div>
              </div>
            )
          }) || []}
        </Slider>
      </div>
    </div>
  )
}
