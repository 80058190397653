import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { IEnum_Content_Contenttype, ISuggest, ISuggestModel } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'

import { PremiumContentIcon } from '../../PremiumContentIcon/PremiumContentIcon'

interface PremiumAutosuggestItemProps {
  data: ISuggest
  search: string
  icon?: boolean
}

export const PremiumAutosuggestItem: React.FC<PremiumAutosuggestItemProps> = ({ data, search, icon = true }) => {
  const contentType = data.model === ISuggestModel.Contents ? (data.logo as IEnum_Content_Contenttype) : null
  const logo = useDefaultLogo(data.logo)

  return (
    <Link href={data.slug} className="flex w-full relative">
      <div className="h-[50px] w-full flex bg-white hover:bg-gray-400 cursor-pointer items-center px-10">
        {icon &&
          (contentType ? (
            <PremiumContentIcon contentType={contentType} size="md" />
          ) : (
            <Image
              className="border border-solid border-mist rounded-[.1875rem]"
              src={logo}
              alt={data.slug}
              width={40}
              height={40}
            />
          ))}
        <Highlighter
          className={cn(`font-primary font-normal text-[1rem] text-[#162e64] leading-[16px] ${icon && 'ml-6'}`)}
          highlightClassName="font-bold bg-white text-[#162e64]"
          searchWords={[search]}
          textToHighlight={data.keyword}
        />
      </div>
    </Link>
  )
}
