import cn from 'classnames'
import React from 'react'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'

export interface PremiumPanelProps {
  icon?: React.ReactNode
  title: string
  description: string
  url: string
  className?: string
  isVendor?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const PremiumContentCategory: React.FC<PremiumPanelProps> = ({
  icon,
  title,
  description,
  className,
  url,
  isVendor = false,
}) => {
  return (
    <>
      {isVendor ? (
        <CompatibleLink
          href={url}
          passHref
          className={cn(className, 'flex flex-row py-[24px] pr-[14px] ps-[20px] bg-white rounded-[10px]')}
        >
          <div className="flex flex-col ... " style={{ minHeight: '164px' }}>
            <div className="flex flex-row ">
              <div className="w-[40px] h-[40px] mr-2 flex justify-center items-center relative">
                <div className="absolute top-0 right-0 bottom-0 left-0 rounded-[20px] opacity-20 bg-navybright" />
                {icon}
              </div>
              <p
                className="flex items-center text-black font-secondary text-[14px] font-bold text-left"
                style={{ color: '#333333' }}
              >
                {title}
              </p>
            </div>

            <div className="flex flex-row items-start  text-left mt-1">
              <div
                style={{ lineHeight: '18px', overflow: 'hidden', maxHeight: '100%' }}
                className="homeGuidBoxDescription font-primary text-[16px] text-shade mt-1 "
              >
                {description}
              </div>
            </div>
          </div>
        </CompatibleLink>
      ) : (
        <CompatibleLink
          href={url}
          passHref
          className={cn(className, 'flex flex-row  py-[24px] pr-[14px] ps-[20px] bg-white rounded-[10px] mt-1')}
        >
          <div className="w-[40px] h-[40px] mr-[20px] flex justify-center items-center relative">
            <div className="absolute h-[40px] w-[40px] top-0 right-0 bottom-0 left-0 rounded-[20px] opacity-20 bg-navybright" />
            {icon}
          </div>
          <div className="flex flex-col items-start !max-w-[1100px]" style={{ minHeight: '136px', marginTop: '-1px' }}>
            <p className="homeGuidBoxHeading leading-[35px] text-black font-secondary text-[24px] font-bold">{title}</p>
            <div
              style={{
                lineHeight: '18px',
                overflow: 'hidden',
                maxHeight: '100%',
                minWidth: '237px  !important',
                width: '237px  !important',
              }}
              className="homeGuidBoxDescription font-primary text-[16px] text-shade mb-1 mt-1"
            >
              {description}
            </div>
          </div>
        </CompatibleLink>
      )}
    </>
  )
}
