import React from 'react'
import { IPopularTopic } from 'src/graphql/generated/hooks'

interface PopularTopicItemProps {
  topic: IPopularTopic
  onMouseOver: () => void
}

export const PopularTopicItem: React.FC<PopularTopicItemProps> = ({ topic, onMouseOver }) => {
  return (
    <li
      className="cursor-pointer text-center md:text-left font-secondary text-textdark font-medium text-[1rem] tracking-normal hover:underline hover:font-bold hover:decoration-turquoise hover:decoration-solid hover:decoration-[.3125rem] pb-3"
      onMouseOver={onMouseOver}
    >
      {topic.name}
    </li>
  )
}
