import IonIcon from '@reacticons/ionicons'
import React, { useState } from 'react'

import { NewsletterModal } from './NewsletterModal'

export const NewsletterButton: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  function showModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <>
      <button className="flex flex-row items-center cursor-pointer" type="button" onClick={showModal}>
        <IonIcon className="text-white mr-3" name="mail" />
        <span className="font-primary font-normal text-white opacity-70 text-[0.875rem] mr-3">
          Subscribe to Insights
        </span>
      </button>

      <NewsletterModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  )
}
