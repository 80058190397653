import React, { memo, useState } from 'react'
import { HomeTopics } from 'src/components/HomeTopics/HomeTopics'
import { PremiumSubTopics } from 'src/components/PremiumSubTopics/PremiumSubTopics'
import { PremiumCategoriesTab } from 'src/components/Tabs/PremiumCategoriesTab'
import { ISubTopicItemFragment, ITopicItemFragment } from 'src/graphql/generated/hooks'

export interface PremiumCategoriesProps {
  premiumCategories: (ITopicItemFragment | ISubTopicItemFragment)[]
  topics: ITopicItemFragment[]
}

export const PremiumCategories: React.FC<PremiumCategoriesProps> = memo(({ premiumCategories, topics }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="w-full px-3 large-lg:px-0 min-h-[20.75rem] bg-clouds pb-16 large-lg:pt-[30px]">
      <div className="!max-w-[1100px] flex flex-col justify-center mx-auto">
        <div className="flex items-center flex-row md:mx-auto mt-[2rem]">
          <PremiumCategoriesTab id={0} activeTab={activeTab} onSelectTab={setActiveTab}>
            PREMIUM CATEGORIES
          </PremiumCategoriesTab>
          <PremiumCategoriesTab id={1} activeTab={activeTab} onSelectTab={setActiveTab}>
            ALL TOPICS
          </PremiumCategoriesTab>
        </div>
        <hr />
        {activeTab === 0 && <PremiumSubTopics className="mt-[3rem]" premiumCategories={premiumCategories} />}
        {activeTab === 1 && (
          <div className="mb-2 mt-[3rem]">
            <HomeTopics className="" topics={topics} />
          </div>
        )}
      </div>
    </div>
  )
})
