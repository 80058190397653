import { Hidden } from '@mui/material'
import cn from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import React from 'react'
// import Link from 'next/link'
// import LogoSVG from 'src/assets/dark_bg_logo.svg'
import StarSVG from 'src/assets/ic-four-star.png'
import { PremiumSearchBox } from 'src/components/global/Searchbox/PremiumSearchBox'
import { Searchbox } from 'src/components/global/Searchbox/Searchbox'
import { ThemeOnly } from 'src/components/global/ThemeOnly'
import { IltaHeader } from 'src/components/IltaHeader/IltaHeader'
import { apolloSdk } from 'src/graphql/apolloSdk'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

import { NewsletterButton } from '../Newsletter/NewsletterButton'

interface Props {
  isPremiumPreview: boolean
  onPreviewPremium: () => void
}

// const conference = (
//   <>
//     <Image src={LogoSVG} width="200" height="80" alt="" className="mt-6" />
//     <p className="text-[1.8rem] text-turquoise font-secondary font-semibold leading-[3rem] tracking-normal text-center">
//       <Link href="https://www.clmsimplifiedacademy.com" passHref className="underline" target="_blank">
//         Announcing CLM Simplified Academy
//       </Link>
//       <br />
//       March 10, 2023, NYC
//       <br />
//       <Link
//         href="https://www.clmsimplifiedacademy.com"
//         passHref
//         className="text-[1.2rem] underline underline-offset-[0.75rem] decoration-turquoise decoration-solid decoration-[.1rem] cursor-pointer"
//         target="_blank"
//       >
//         Sign up here
//       </Link>
//     </p>
//   </>
// )

export const Hero: React.FC<Props> = ({ isPremiumPreview, onPreviewPremium }) => {
  const { session, isPremium } = useAuthenticated()
  const userName = session?.user.name || ''
  const isPremiumMode = isPremium || isPremiumPreview
  const [bannerContent, setBannerContent] = useState<any>()

  const router = useRouter()

  const handleClick = () => {
    // Redirect to a specific page, e.g., '/about'
    if (bannerContent && bannerContent?.externalLink) {
      // router.push(`${bannerContent?.externalLink}`)
      window.open(`${bannerContent?.externalLink}`, '_blank', 'noopener,noreferrer')
    } else {
      router.push('/')
    }
  }

  const fetchdata = async () => {
    try {
      const banner: any = await apolloSdk.getBannerAnnouncement()
      if (banner) setBannerContent(banner?.bannerAnnouncement?.data?.attributes)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error------ \n', error, onPreviewPremium)
    }
  }

  useEffect(() => {
    fetchdata()
  }, [])

  return (
    <>
      <ThemeOnly theme="legaltech">
        <div
          className={cn(
            isPremiumMode
              ? 'w-full large-lg:px-0 min-h-[25rem] bg-navy pt-10'
              : 'w-full px-3 large-lg:px-0 min-h-[25rem] bg-navy pt-[3rem]',
          )}
          style={{
            backgroundImage: 'url(' + '/banner-bg.png' + ')',
            width: '100%',
            backgroundSize: 'cover',
          }}
        >
          <div className="!max-w-[1100px] flex flex-col items-center m-auto pl-[1.275rem] pr-[1.275rem]">
            {isPremiumMode && (
              <>
                <p className="text-white text-[.8rem] font-semibold mt-[20px]">Welcome {userName}!</p>
                <h2 className="max-w-[47rem] md:mt-[2.5rem] md:pt-[0rem] pt-[3.175rem] md:text-[2.4rem] text-[1.85rem] text-white font-secondary font-semibold md:leading-[3rem] leading-[2.3rem] tracking-normal text-center">
                  Find, research and connect to the{' '}
                  <span className="underline md:underline-offset-[0.75rem] underline-offset-[0.4rem] decoration-turquoise decoration-solid decoration-[.3125rem]">
                    legaltech solutions
                  </span>{' '}
                  you need
                </h2>
              </>
            )}
            {!isPremiumMode && (
              <>
                {/* {conference} */}
                <h2 className="max-w-[47rem] md:mt-[1.5rem] md:pt-[0rem] pt-[3.175rem] md:text-[2.5rem] text-[1.85rem] text-white font-secondary font-semibold md:leading-[3rem] leading-[2.3rem] tracking-normal text-center">
                  Find, research and connect to the{' '}
                  <span className="underline md:underline-offset-[0.75rem] underline-offset-[0.4rem] decoration-turquoise decoration-solid decoration-[.3125rem]">
                    legaltech solutions
                  </span>{' '}
                  you need
                </h2>
              </>
            )}
            {!isPremiumMode && (
              <p className="text-white mt-5 font-primary font-light md:text-[1.125rem] text-[1rem] leading-[1.375rem] text-center">
                Research the market for legaltech solutions
              </p>
            )}
            {isPremiumMode ? (
              <PremiumSearchBox className="w-[18rem] sm:w-[600px] md:mt-[2.75rem] mt-[1.25rem] mb-1 z-9" />
            ) : (
              <Searchbox className="w-[18rem] sm:w-[25rem] md:mt-[2.75rem] mt-[1.25rem] mb-1" />
            )}
            <div className="mb-[40px]">
              <NewsletterButton />
            </div>
          </div>
          {/* {!isPremiumMode && ( */}
          <Hidden lgDown>
            {bannerContent && bannerContent?.title && (
              <div className="flex flex-row items-center justify-center bg-lightRoyalBlue min-w-full max-w-[55rem] text-[1.0rem] text-turquoise font-secondary leading-[3rem] tracking-normal text-center">
                <Image src={StarSVG} className="w-[30px] h-[25px]" width={30} height={25} alt="" />
                <span className="mx-5"></span>
                {bannerContent?.title} &nbsp;
                <button
                  className="ml-2 text-white text-[1.0rem] underline underline-offset-[0.1rem] decoration-white decoration-solid decoration-[.1rem] cursor-pointer"
                  onClick={handleClick}
                >
                  Learn more
                </button>
                {/* Legaltech Hub Premium is now available!&nbsp;
              <button
                className="ml-2 text-white text-[1.0rem] underline underline-offset-[0.1rem] decoration-white decoration-solid decoration-[.1rem] cursor-pointer"
                onClick={onPreviewPremium}
              >
                Learn more
              </button>*/}
                <span className="mx-5"></span>
                <Image src={StarSVG} className="w-[30px] h-[25px]" width={30} height={25} alt="" />
              </div>
            )}
          </Hidden>
          {/*  )}  */}
        </div>
      </ThemeOnly>

      <ThemeOnly theme="ilta">
        <IltaHeader displayTitle={false} className="!min-h-[50px] !items-center" menuClassName="!top-[50px]" />
        <div className="w-full px-3 large-lg:px-0 min-h-[25rem] bg-hero">
          <div className="!max-w-[1100px] flex flex-col m-auto pl-[1.275rem] pr-[1.275rem] items-center">
            <h1 className="max-w-[47rem] md:mt-[5.5rem] md:pt-[0rem] pt-[3.175rem] md:text-[50px] text-[1.85rem] text-white font-primary font-black md:leading-[3rem] leading-[2.3rem] tracking-normal text-left h-[60px]">
              Sponsor Directory
            </h1>
            <h3 className="text-white text-[24px] mt-[12px] font-secondary font-regular">
              Find, research and connect to the legaltech solutions you need
            </h3>
            <Searchbox className="w-[18rem] sm:w-[25rem] md:mt-[2rem] mt-[1.25rem] mb-10" />
          </div>
        </div>
      </ThemeOnly>
    </>
  )
}
