import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { IPopularTopicListing } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'

interface PopularTopicListingProps {
  vendor: IPopularTopicListing
}

export const PopularTopicListing: React.FC<PopularTopicListingProps> = ({ vendor }) => {
  const logo = useDefaultLogo(vendor.logo?.url)
  const type = vendor.type

  let link
  if (type === 'ALSP') {
    link = `/alsps/${vendor.slug}`
  } else if (type === 'Consultancy') {
    link = `/consultants/${vendor.slug}`
  } else {
    link = `/vendors/${vendor.slug}`
  }

  return (
    <Link
      href={link}
      className="bg-white rounded-[.5rem] lg:w-[13.375rem] aspect-[214/190] flex items-center justify-center"
    >
      <Image
        src={logo}
        className="bg-white rounded-[.5rem] lg:w-[13.375rem] aspect-[214/190] flex items-center justify-center"
        width={1000}
        height={1000}
        alt="logo"
      />
    </Link>
  )
}
