import cn from 'classnames'
import React, { useState } from 'react'

interface PremiumSearchBoxTabsProps {
  tabs: string[]
  counts: number[]
  showNumber?: boolean
  className?: string
  onTabSelect: (tab: number) => void
}

/**
 * Primary UI component for user interaction
 */
export const PremiumSearchBoxTabs: React.FC<PremiumSearchBoxTabsProps> = ({
  tabs,
  counts,
  className,
  showNumber = true,
  onTabSelect,
}) => {
  const [selectedIdx, setSelectedIdx] = useState(0)

  const tabChanged = (idx: number) => {
    setSelectedIdx(idx)
    onTabSelect(idx)
  }

  return (
    <div className="h-[2.5rem] flex flex-row justify-center">
      {tabs.map((title: string, idx: number) => {
        const count = counts[idx] || 0
        return (
          <div
            className={cn(className, 'flex text-center pl-4 pr-4 text-[0.8rem] text-textdark font-primary', {
              'font-bold border-b-[3px] border-b-navy': selectedIdx === idx,
              'font-regular border-b-[3px] border-b-transparent opacity-80': selectedIdx !== idx,
            })}
            key={idx}
            onClick={() => tabChanged(idx)}
          >
            <span className="m-auto text-[0.8rem] cursor-pointer">
              {showNumber ? `${title.toUpperCase()} (${count})` : `${title.toUpperCase()}`}
            </span>
          </div>
        )
      })}
    </div>
  )
}
