import cn from 'classnames'
import React from 'react'

interface Props {
  className?: string
  activeClassName?: string
  inactiveClassName?: string
  id: number
  activeTab: number
  onSelectTab: (activeTab: number) => void
  children?: React.ReactNode
}

export const PremiumCategoriesTab: React.FC<Props> = ({
  id,
  activeTab,
  activeClassName,
  inactiveClassName,
  className,
  children,
  onSelectTab,
}) => (
  <button
    className={cn(
      'md:w-[15rem] flex-1 flex flex-col justify-center text-center font-primary font-bold text-[1.0rem]',
      activeTab === id ? 'border-b-2 border-b-navy' : '',
      className,
      activeTab === id ? activeClassName : inactiveClassName,
    )}
    onClick={() => onSelectTab(id)}
  >
    {activeTab === id ? (
      <span className="m-auto text-textdark pb-[16px]">{children}</span>
    ) : (
      <span className="m-auto text-[#51648c] pb-[16px]">{children}</span>
    )}
  </button>
)
