import { OriginItem, POP_ORIGIN_URL, PUSH_ORIGIN_URL } from '../types/origin'

export const push = (originItem: OriginItem) => ({
  type: PUSH_ORIGIN_URL,
  originItem,
})

export const pop = () => ({
  type: POP_ORIGIN_URL,
})
